import resume from "../assets/pdf/resume.pdf";
import profileImage from "../assets/png/profile-purple.png";

export const headerData = {
  name: "Abdul Qadir",
  title: "Senior Frontend Developer",
  desciption: `I am a Senior Frontend Developer with 4+ years of experience. I started my career as a web developer, focusing on HTML, CSS, JavaScript, and Wordpress. This foundational experience provided me with a solid understanding of web fundamentals, transitioned to MERN Stack, delivering successful projects on Fiverr. Thrived at Atompoint, successfully completing two projects before joining 433 Football as a Senior Frontend Developer. Recognized for my skills, I progressed to Frontend Team Lead, committed to continuous learning and engineering excellence.`,
  image: profileImage,
  resumePdf: resume,
};
