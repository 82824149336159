export const experienceData = [
  {
    id: 1,
    company: "Cloud Primero - Allsetra",
    jobtitle: "Senior Frontend Developer",
    startYear: "March, 2024 ",
    endYear: " Present",
  },
  {
    id: 2,
    company: "Brandverse - Chikoo",
    jobtitle: "Senior Frontend Developer",
    startYear: "May, 2023 ",
    endYear: " Feb-2024",
  },
  {
    id: 3,
    company: "Cloud Primero - 433 Football",
    jobtitle: "Senior Frontend Developer",
    startYear: "April, 2022 ",
    endYear: " April, 2023",
  },
  {
    id: 4,
    company: "Atompoint",
    jobtitle: "Frontend Developer",
    startYear: "April, 2021 ",
    endYear: " March,2022",
  },
];
