/* eslint-disable */
import girl1 from "../assets/svg/testimonials/girl1.svg";
import girl2 from "../assets/svg/testimonials/girl2.svg";
import girl3 from "../assets/svg/testimonials/girl3.svg";
import boy1 from "../assets/svg/testimonials/boy1.svg";
import boy2 from "../assets/svg/testimonials/boy2.svg";
import boy3 from "../assets/svg/testimonials/boy3.svg";

export const testimonialsData = [
  {
    id: 1,
    name: "lucagalante158",
    title: "Website Owner",
    text: "Abdul is extremely professional and responsive, was great working with him.",
    image: boy3,
  },
  {
    id: 2,
    name: "Ryan Mozzarella",
    title: "Head Engineer",
    text: "Absolutely incredible job. He guided me through running this locally and got everything working as intended. Thank you so much, aqadirsscl! It was a pleasure working with you.",
    image: boy2,
  },
  {
    id: 3,
    name: "Rudeshko",
    title: "Senior Web Developer",
    text: "Very fast delivery, and was able to cover all of the requirements.",
    image: boy3,
  },
  {
    id: 4,
    name: "a2jimenez",
    title: "Crypto Website Owner",
    text: "Good communication and very engage to deliver a good solution",
    image: boy2,
  },
  {
    id: 5,
    name: "Tom",
    title: "Website Owner",
    text: "What a great programmer - will 100% use again. Rewrote the whole site in react in super fast time. Perfect English, great skills a real good find I would 100% recommend this person",
    image: boy1,
  },
];
